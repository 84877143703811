<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import { VxCellDate, VxCellPlayerAvatar, VxTable, VxCellTextCopy } from '@/components/table'
import { passDataToResource, provablyFair } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { pad } from 'lodash'

export default {
  name: 'ServerSeeds',
  components: {
    VxTable,
    VxCellDate,
    VxCellPlayerAvatar
  },
  setup () {
    const resource = passDataToResource(provablyFair.serverSeeds, {
      requestParams: {
        params: { include: 'player' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        label: 'Player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'public_hash',
        component: VxCellTextCopy
      },
      {
        key: 'secret_salt',
        component: VxCellTextCopy,
        tdAttr: {
          emptyText: pad('', 16, '*')
        }
      },
      {
        key: 'secret_hash',
        component: VxCellTextCopy,
        tdAttr: {
          emptyText: pad('', 16, '*')
        }
      },
      {
        key: 'nonce',
        sortable: true
      },
      {
        key: 'created_at',
        component: VxCellDate
      },
      {
        key: 'updated_at',
        component: VxCellDate,
        sortable: true
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
